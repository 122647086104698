import React, { Component } from 'react';
import DatePicker from 'react-calendar';

import './Form.css';
import { normalizeInput } from '../../../helpers/utils';
import { sendVacationOptions } from '../../../services/apiService';
import { formatDateApi, formatDateCalendar } from '../../../helpers/dateHelper';

class Form extends Component {
  autoCloseFormDelay = 3000;

  constructor(props) {
    super(props);
    this.state = {
      name: {
        label: 'Name',
        value: '',
        hasError: true,
        errorText: 'Please provide a name',
        validation: val => val !== '',
        onChange: val => val
      },
      email: {
        label: 'Email*',
        value: '',
        hasError: true,
        errorText: 'Please provide a correct email address',
        validation: val => this.validateEmail(val),
        onChange: val => val
      },
      contactNumber: {
        label: 'Contact Number*',
        value: '',
        hasError: true,
        errorText: 'Please provide a contact number',
        validation: val => val !== '',
        onChange: (newVal, oldVal) => {
          return normalizeInput(newVal, oldVal);
        }
      },
      destination: {
        label: 'Interested destinations',
        value: 'EUROPE',
        hasError: false,
        validation: val => val,
        onChange: val => val
      },
      travelers: {
        label: 'Number of travelers',
        value: 1,
        hasError: false,
        validation: val => val,
        onChange: val => val
      },
      duration: {
        label: 'Duration of your holiday',
        value: 7,
        hasError: false,
        validation: val => val,
        onChange: val => val
      },
      departureDate: new Date(),
      isShowError: false,
      displaySuccessMessage: false,
      isCalendarOpen: false
    };
  }

  componentWillUnmount() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
  }

  validateEmail = val => {
    return /^[^@]+@[^@]+\.[^@]+$/.test(val);
  };

  handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      ...this.state,
      [name]: {
        ...this.state[name],
        value: this.state[name].onChange(value, this.state[name].value),
        hasError: !this.state[name].validation(value)
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.displaySuccessMessage) {
      return;
    }
    if (Object.values(this.state).some(({ hasError }) => hasError)) {
      this.setState({ ...this.state, isShowError: true });
    } else {
      const {
        name,
        email,
        contactNumber,
        destination,
        travelers,
        departureDate,
        duration
      } = this.state;
      sendVacationOptions({
        name: name.value,
        phone: contactNumber.value,
        email: email.value,
        destination: destination.value,
        travelers: travelers.value,
        departureDate: formatDateApi(departureDate),
        duration: duration.value
      });
      this.setState({
        displaySuccessMessage: true,
        isCalendarOpen: false
      });
      this.timeoutID = setTimeout(() => {
        this.toggleForm();
        this.resetForm();
      }, this.autoCloseFormDelay);
    }
  };

  resetForm = () => {
    const {
      name,
      email,
      contactNumber,
      destination,
      travelers,
      duration
    } = this.state;
    this.setState({
      name: { ...name, value: '' },
      email: { ...email, value: '' },
      contactNumber: { ...contactNumber, value: '' },
      destination: { ...destination, value: '' },
      travelers: { ...travelers, value: '' },
      departureDate: { value: new Date() },
      duration: { ...duration, value: '' }
    });
  };

  toggleForm = () => {
    const { toggleForm, showForm } = this.props;

    toggleForm(!showForm);
    this.setState({
      isCalendarOpen: false
    });
  };

  openCalendar = () => {
    if (!this.state.displaySuccessMessage) {
      this.setState({
        isCalendarOpen: true
      });
    }
  };
  closeCalendar = () => {
    this.setState({
      isCalendarOpen: false
    });
  };
  onDateChange = value => {
    this.setState({
      departureDate: value
    });
    this.closeCalendar();
  };

  render() {
    const {
      name,
      email,
      contactNumber,
      destination,
      travelers,
      departureDate,
      duration,
      isShowError,
      displaySuccessMessage,
      isCalendarOpen
    } = this.state;
    const { showForm } = this.props;
    return (
      <React.Fragment>
        <div
          className={`vacation-form-wrapper ${showForm ? 'show' : ''} ${
            displaySuccessMessage ? 'blur' : ''
          }`}
        >
          <div className="form">
            <button
              className="banner__container--close-btn"
              onClick={this.toggleForm}
            >
              x
            </button>
            <form className="formContact-from" data-subscription-form>
              <div className="submit-block">
                <h4>Tell us your travel plans</h4>
                <div className="submit-wrapper d-flex">
                  <div className="input">
                    <label className="label" htmlFor="name">
                      {name.label}
                    </label>
                    <input
                      id="name"
                      className="submit-input"
                      type="text"
                      name="name"
                      value={name.value}
                      onChange={this.handleChange}
                      required
                      disabled={displaySuccessMessage}
                    />
                    {isShowError && name.hasError && (
                      <p className="error-text">{name.errorText}</p>
                    )}
                  </div>
                  <div className="input">
                    <label className="label" htmlFor="contactNumber">
                      {contactNumber.label}
                      <input
                        id="contactNumber"
                        className="submit-input"
                        type="tel"
                        name="contactNumber"
                        value={contactNumber.value}
                        onChange={this.handleChange}
                        required
                        placeholder="+1-123-245-6789"
                        disabled={displaySuccessMessage}
                      />
                      {isShowError && contactNumber.hasError && (
                        <p className="error-text">{contactNumber.errorText}</p>
                      )}
                    </label>
                  </div>
                  <div className="input">
                    <label className="label" htmlFor="email">
                      {email.label}
                    </label>
                    <input
                      id="email"
                      className="submit-input"
                      type="text"
                      name="email"
                      value={email.value}
                      onChange={this.handleChange}
                      required
                      disabled={displaySuccessMessage}
                    />
                    {isShowError && email.hasError && (
                      <p className="error-text">{email.errorText}</p>
                    )}
                  </div>
                  <div className="input">
                    <label className="label" htmlFor="destination">
                      {destination.label}
                      <select
                        name="destination"
                        onChange={this.handleChange}
                        value={destination.value}
                        required
                        disabled={displaySuccessMessage}
                      >
                        <option value="EUROPE" defaultValue>
                          Europe
                        </option>
                        <option value="INDIA">India</option>
                        <option value="LATIN_AMERICA">Latin America</option>
                        <option value="CARIBBEAN">Caribbean</option>
                        <option value="AUSTRALIA">Australia</option>
                      </select>
                    </label>
                  </div>
                  <div className="input">
                    <label className="label" htmlFor="travelers">
                      {travelers.label}
                      <input
                        id="travelers"
                        className="submit-input"
                        type="number"
                        max={9}
                        min={1}
                        name="travelers"
                        value={travelers.value}
                        onChange={this.handleChange}
                        required
                        disabled={displaySuccessMessage}
                      />
                    </label>
                  </div>
                  <div className="input">
                    <label className="label" htmlFor="departureDate">
                      Date of travel
                    </label>
                    <input
                      id="departureDate"
                      className="submit-input"
                      type="text"
                      name="departureDate"
                      value={formatDateCalendar(departureDate)}
                      required
                      readOnly
                      onFocus={this.openCalendar}
                      disabled={displaySuccessMessage}
                    />
                    {isCalendarOpen && (
                      <button
                        className="banner__container--close-btn close-calendar"
                        type="button"
                        onClick={this.closeCalendar}
                      >
                        x
                      </button>
                    )}
                    {isCalendarOpen && (
                      <DatePicker
                        isOpen
                        minDate={new Date()}
                        format="MM/dd/yy"
                        onChange={this.onDateChange}
                        value={departureDate}
                        locale="en-EN"
                        prev2Label={null}
                        next2Label={null}
                        minDetail={'month'}
                        showFixedNumberOfWeeks
                      />
                    )}
                  </div>
                  <div className="input">
                    <label className="label" htmlFor="duration">
                      {duration.label}
                      <input
                        id="duration"
                        className="submit-input"
                        type="number"
                        max={150}
                        min={1}
                        name="duration"
                        value={duration.value}
                        onChange={this.handleChange}
                        required
                        disabled={displaySuccessMessage}
                      />
                    </label>
                  </div>

                  <div className="btn-submit">
                    <button
                      className="button-search"
                      type="submit"
                      onClick={this.handleSubmit}
                      disabled={displaySuccessMessage}
                    >
                      Find the options
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {displaySuccessMessage && (
              <div className="thank-you" data-thank-you="">
                <h4 className="h3">
                  Thank you for getting in touch! We'll respond soon.
                </h4>
              </div>
            )}
          </div>
        </div>
        {!displaySuccessMessage && (
          <div className="vacation-trip-button" onClick={this.toggleForm}>
            <div className="img">
              <img src="../../images/icons/flight-details.svg" alt="map" />
            </div>
            <div className="text">Your trip is here!</div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

Form.propTypes = {};

export default Form;
