import React from 'react';
import PropTypes from 'prop-types';

function Dot(props) {
  return (
    <span
      style={{
        display: 'inline-block',
        height: '8px',
        width: '8px',
        borderRadius: '4px',
        backgroundColor: 'white',
        margin: '7px 5px',
        opacity: props.selected ? '1' : '0.3',
        transitionDuration: '300ms'
      }}
    />
  );
}

export default function IndicatorDots(props) {
  const wrapperStyle = {
    position: 'absolute',
    width: '100%',
    zIndex: '100',
    bottom: '0px',
    textAlign: 'center'
  };

  if (props.total < 2) {
    return <div style={wrapperStyle} />;
  } else {
    return (
      <div style={wrapperStyle}>
        {Array.from({ length: props.total }).map((x, index, arr) => {
          return (
            index < 3 && (
              <Dot key={index + arr.length} selected={props.index === index} />
            )
          );
        })}
      </div>
    );
  }
}

IndicatorDots.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};
