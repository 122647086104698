import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import TrustpilotHorizontal from '../components/TrustpilotHorizontal/index';
import VacationCategories from '../components/VacationCategories/VacationCategories';
import SimpleSlider from '../components/VacationCategories/Slider/Slider';
import Form from '../components/VacationCategories/Form/Form';

import './styles.css';

const Vacation = ({ phoneNumber, data }) => {
  const [phoneNum, setPhoneNum] = useState({ phoneNumber });
  const [showForm, toggleForm] = useState(false);

  const description = data.vacationJson.seoDescription;
  const seotitle = data.vacationJson.title;

  const vacationImages = {};
  data.images.edges.forEach(n => {
    vacationImages[n.node.relativePath] = n.node.childImageSharp.fluid;
  });

  return (
    <Layout
      isStaticPage
      passPhoneNumber={phoneNum => {
        setPhoneNum({ phoneNumber: phoneNum });
      }}
    >
      <SEO title={seotitle} description={description} />
      <SimpleSlider data={data} images={vacationImages} />
      <Form showForm={showForm} toggleForm={toggleForm} />
      <div className="main">
        <VacationCategories data={data} images={vacationImages} />
        <button className="btn-find-more" onClick={() => toggleForm(!showForm)}>
          <span className="image">
            <img src="../../images/map-icon.png" alt="map" />
          </span>
          Find more about this itinerary
        </button>
      </div>
      <TrustpilotHorizontal />
    </Layout>
  );
};

export default Vacation;

export const pageQuery = graphql`
  query($name: String) {
    vacationJson(name: { eq: $name }) {
      ...Vacation_details
    }
    images: allFile(filter: { extension: { in: "jpg" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
