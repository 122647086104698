import React from 'react';
import Carousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Image from '../../Image/Image';
import IndicatorDots from './IndicatorDots/IndicatorDots';
import Buttons from './Buttons';
import { getImageSrc } from '../../../helpers/getImagesSrc';
import { getRandomElementsList } from '../../../helpers/utils';
import LinkUrl from '../../LinkUrl';

import './Slider.css';

const COUNT_RANDOM_CARDS_ON_PAGE = 3;

const SimpleSlider = ({ data, images }) => (
  <div className="carousel-wrapper carousel-button-disabled">
    <Carousel auto loop widgets={[IndicatorDots, Buttons]}>
      {getRandomElementsList(
        data.vacationJson.cards,
        COUNT_RANDOM_CARDS_ON_PAGE
      ).map((card, index) => {
        return (
          <div className="slider-wrapper" key={card.slug + index}>
            <div className="image-wrapper">
              <Image image={getImageSrc(card, images)} alt="vacationImage" />
            </div>
            <div className="content">
              <h1>{card.title}</h1>
              <LinkUrl path={`/${card.slug}`} className="button-slider-blog">
                Learn More
              </LinkUrl>
            </div>
          </div>
        );
      })}
    </Carousel>
  </div>
);

export default SimpleSlider;
