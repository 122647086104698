import React from 'react';
import LinkUrl from '../../LinkUrl';
import Image from '../../Image/Image';
import './CardsVacation.css';
import { ImageContent } from '../../ContentVacation/Components/ComponentsContext';

const CardsVacation = ({
  title,
  backgroundImage,
  slug,
  tag,
  backgroundColor,
  imageCard,
  contentImage
}) => {
  return (
    <LinkUrl
      className={`card ${contentImage ? 'card-more' : ''}`}
      path={`/${slug}`}
    >
      <div className="image-wrapper">
        {contentImage ? (
          <ImageContent image={backgroundImage} />
        ) : (
          <Image image={imageCard} alt={backgroundImage} />
        )}
      </div>
      <div className="content">
        <p style={{ background: backgroundColor }} className="tag">
          {tag}
        </p>
        <h4>{title}</h4>
      </div>
    </LinkUrl>
  );
};

export default CardsVacation;
