import React from 'react';
import propTypes from 'prop-types';

const styles = {
  wrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: '100',
    bottom: '0',
    textAlign: 'center'
  },
  btn: {
    width: '15px',
    height: '15px',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'absolute',
    bottom: '200px',
    font: '16px/30px sans-serif',
    color: 'rgba(255,255,255,0.8)',
    transform: 'rotate(45deg)'
  },
  left: {
    left: '20px',
    borderBottom: '2px solid #ffffff',
    borderLeft: '2px solid #ffffff'
  },
  right: {
    right: '20px',
    borderTop: '2px solid #ffffff',
    borderRight: '2px solid #ffffff'
  }
};

export default function Buttons({
  index,
  total,
  loop,
  prevHandler,
  nextHandler
}) {
  const prevBtnStyle = { ...styles.btn, ...styles.left };
  const nextBtnStyle = { ...styles.btn, ...styles.right };
  return (
    <div style={styles.wrapper}>
      {(loop || index !== 0) && (
        <div style={prevBtnStyle} onClick={prevHandler}></div>
      )}
      {(loop || index !== total - 1) && (
        <div style={nextBtnStyle} onClick={nextHandler}></div>
      )}
    </div>
  );
}

Buttons.propTypes = {
  index: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
  prevHandler: propTypes.func,
  nextHandler: propTypes.func
};
