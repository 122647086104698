import React from 'react';
import ImageVacation from '../../Image/ImageVacation';

export const Text = ({
  type: Tag,
  display = 'block',
  textAlign = 'left',
  textDecoration = 'none',
  fontStyle = 'normal',
  fontWeight = 'normal',
  pt = '10',
  pb = '10',
  pl = '0',
  width = 'auto',
  verticalAlign = 'baseline',
  href,
  children
}) => {
  const style = {
    display: display,
    textAlign: textAlign,
    textDecoration: textDecoration,
    fontStyle: fontStyle,
    fontWeight: Tag !== 'p' ? 'bold' : fontWeight,
    paddingTop: `${pt}px`,
    paddingBottom: `${pb}px`,
    paddingLeft: `${pl}px`,
    width,
    verticalAlign
  };

  return Tag === 'a' ? (
    <Tag href={href} target="_blank" style={style}>
      {children}
    </Tag>
  ) : (
    <Tag style={style}>{children}</Tag>
  );
};

export const ImageContent = ({
  image,
  mt,
  mb,
  display = 'block',
  width = 'auto'
}) => {
  const style = {
    marginTop: `${mt}px`,
    marginBottom: `${mb}px`,
    display,
    width
  };

  return <ImageVacation style={style} alt="blogVacation" filename={image} />;
};

export const Container = ({ children, as: ElementType = 'div', styles }) => {
  const { display = 'block', width = 'auto', ...rest } = styles;
  const style = {
    display,
    width,
    ...rest
  };

  return <ElementType style={style}>{children}</ElementType>;
};
