import React, { useState, useEffect } from 'react';
import CardsVacation from './CardsVacation/CardsVacation';
import SubscribeForm from '../SubscribeForm';
import Pagination from '../Pagination/Pagination';
import { useWindowSize } from '../../helpers/useWindowSize';
import { getImageSrc } from '../../helpers/getImagesSrc';

import './VacationCategories.css';

const VacationCategories = ({ data, images }) => {
  const tags = [
    {
      text: 'All',
      value: 'all',
      backgroundColor: '#FFFFFF'
    },
    {
      text: 'America',
      value: 'america',
      backgroundColor: '#C44242'
    },
    {
      text: 'India',
      value: 'india',
      backgroundColor: '#885BDC'
    },
    {
      text: 'Europe',
      value: 'europe',
      backgroundColor: '#DC4285'
    },
    {
      text: 'Asia',
      value: 'asia',
      backgroundColor: '#E1AD1E'
    },
    {
      text: 'Africa',
      value: 'africa',
      backgroundColor: '#72A912'
    },
    {
      text: 'Australia',
      value: 'australia',
      backgroundColor: '#FF784F'
    }
  ];
  const [width] = useWindowSize();

  let toggleQuantityCards = width < 425;

  let cardsLength = 0;

  const [currentTag, setCurrentTag] = useState('all');
  const [cards, setCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCards(data.vacationJson.cards);
  }, []);

  const cardsPerPage = toggleQuantityCards ? 3 : 10;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const findCardsCurrent = currentTag =>
    currentCards.filter((card, i) =>
      currentTag === 'all' ? card : card.tag === currentTag
    );

  const findCards = currentTag =>
    data.vacationJson.cards.filter((card, i) =>
      currentTag === 'all' ? card : card.tag === currentTag
    );

  const CurrentTag = tag => {
    setCurrentTag(tag);
    setCards(findCards(tag));
    setCurrentPage(1);
  };
  return (
    <div className="wrapper-blog">
      <ul className="tabs-categories">
        <li className="tab-text">Categories:</li>
        {tags.map((tag, index, arr) => (
          <li
            style={{ background: tag.backgroundColor }}
            className={currentTag === tag.value ? 'active' : ''}
            key={arr.length + index}
            onClick={() => CurrentTag(tag.value)}
          >
            {tag.text}
          </li>
        ))}
      </ul>
      <div className="wrapper-card">
        {findCardsCurrent(currentTag).map((card, index, arr) => {
          cardsLength = arr.length;

          return (
            <React.Fragment key={arr.length + index}>
              <CardsVacation imageCard={getImageSrc(card, images)} {...card} />
              {(width > 1024 ? index === 4 : index === 3) && <SubscribeForm />}
            </React.Fragment>
          );
        })}
        {(width > 1024 ? cardsLength < 5 : cardsLength < 4) && (
          <SubscribeForm />
        )}
        {cards.length >= 10 && (
          <Pagination
            cardsPerPage={cardsPerPage}
            totalCards={cards.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};

export default VacationCategories;
