import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { subscribeToNewsletter } from '../../services/apiService';
import SuggestionsList from '../FindFlightsBlock/SearchForm/components/AirpotInput/SuggestionsList';
import { getThemeName } from '../../helpers/urlParams';

const SubscribeForm = ({ isInnerPage, smallSize = false }) => {
  const dataTheme = getThemeName();
  const [inputValue, changeValue] = React.useState('');
  const [emailAgreement, setEmailAgreement] = React.useState(false);

  const [displaySuccessMessage, changeDisplaySuccessMessage] = React.useState(
    false
  );
  const [displayError, changeDisplayError] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    if (displayError || inputValue === '' || !emailAgreement) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [emailAgreement, inputValue, displayError]);

  const handleInputChange = e => {
    const trimmedInput = e.target.value.replace(/\s/g, '');
    if (/^[^@]+@[^@]+\.[^@]+$/.test(trimmedInput)) {
      changeDisplayError(false);
      changeValue(e.target.value);
      return;
    }
    changeDisplayError(true);
    changeValue(e.target.value);
  };
  const handleSubmit = e => {
    e.preventDefault();
    changeDisplaySuccessMessage(!displaySuccessMessage);
    subscribeToNewsletter({
      email: inputValue,
      source: 'subscribeToNewsletter'
    });
  };
  return (
    <React.Fragment>
      <div
        className={`${smallSize ? 'small-size' : 'block-wrapper'} d-flex ${
          isInnerPage ? 'inner-page-block-wrapper' : ''
        }`}
      >
        <div className="subscribe-wrapper d-flex" data-theme={dataTheme}>
          <form className="subscribe-form d-flex" data-subscription-form>
            <div className="img-wrapper">
              <div className="subscribe-icon"></div>
            </div>
            <div className="submit-block">
              <h3>Subscribe to our newsletter and save!</h3>
              <p>Receive hot deals, secret promos and new articles</p>
              {!displaySuccessMessage && (
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="consent-checkbox"
                    name="consent-checkbox"
                    onChange={e => setEmailAgreement(e.target.checked)}
                  />
                  <label for="consent-checkbox">
                    I agree to receive email from Travelopod
                  </label>
                </div>
              )}
              {!displaySuccessMessage && (
                <div className="submit-wrapper d-flex">
                  <label>
                    <input
                      className="submit-input"
                      value={inputValue}
                      onChange={handleInputChange}
                      placeholder="your.email@mail.com"
                    />
                  </label>
                  <div className="btn-submit">
                    <button
                      disabled={disabled}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              )}
              {displayError && (
                <span className="input-error">
                  Please provide a correct email address
                </span>
              )}

              {displaySuccessMessage && (
                <div className="submit-block success d-flex">
                  <span>Success</span>
                  <div className="success-icon" />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscribeForm;

SubscribeForm.propTypes = {
  isInnerPage: PropTypes.bool
};

SuggestionsList.defaultProps = {
  isInnerPage: false
};
